
import { defineComponent, ref, nextTick } from 'vue';
import useDealerPrepaymentQualified from '@/hooks/SalesReporting/PrepaymentQualified/Dealer/useDealerPrepaymentQualified';
// const tableWidth = window.innerWidth;
export default defineComponent({
    setup() {
        const searchCon = ref();
        const tableHeight = ref();
        nextTick(() => {
            (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height =
                window.innerHeight -
                220 -
                searchCon.value.$el.scrollHeight +
                'px';
            const height = (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height;
            const heightNum = parseInt(height.substr(0, height.indexOf('px')));
            tableHeight.value = heightNum - 110;
        });

        const {
            searchParams,
            activeTab,
            tabChange,
            unQulifiedList,
            qulifiedList,
            qulifiedCol,
            unQulifiedCol,
            searchClick,
            resetClick,
            downloadClick,
            handleBuChange,
            buList,
            subRegionList,
            tableWidth,
        } = useDealerPrepaymentQualified();

        return {
            handleBuChange,
            searchCon,
            tableWidth,
            tableHeight,
            qulifiedCol,
            unQulifiedCol,
            searchParams,
            buList,
            subRegionList,
            activeTab,
            tabChange,
            unQulifiedList,
            qulifiedList,
            searchClick,
            resetClick,
            downloadClick,
        };
    },
});
